import store from "@/store";
export default {
  name: 'ShoeLog',
  computed: {
    opendate: {
      get() {
        return store.state.schedule.movelocation.openshoedate;
      }
    },
    getShoeLog: {
      get() {
        return store.getters['schedule/ShoeList'].map((item) => {
          item.show = false;
          return item;
        });
      }
    }
  },
  methods: {
    toggleshow(data) {
      console.log(data);
      const index = this.showdate.indexOf(data.date);
      if (index > -1) {
        this.showdate.splice(index, 1)
      } else {
        this.showdate.push(data.date);
      }
    },
    shoedate(date) {
      if (this.opendate == date) {
        store.commit("schedule/setOpenShoeDate", "");
        // this.$emit("update:opendate", "");
      } else {
        store.commit("schedule/setOpenShoeDate", date);
        // this.$emit("update:opendate", date);
        this.shoeclose();
      }
    },
    shoeclose() {
      this.showdate = []
      this.$emit("update:isopen", false);
    }
  },

  data() {
    return {
      show: false,
      showdate: []
    }
  },
  watch: {
    isopen(data) {
      this.show = data;
      if (data) {
        store.dispatch("schedule/getShoeLog");
      }
    }
  },
  props: {
    isopen: Boolean,
    // opendate: String
  },
};
