import store from "@/store";
import "leaflet/dist/leaflet.css";
import draggable from 'vuedraggable';
import {
  LMap,
  LTileLayer,
  LMarker,
  LPopup,
  LTooltip,
  LControlLayers,
  LIcon,
  LPolyline,
  LLayerGroup,
  LCircleMarker,
  LControlZoom
} from 'vue2-leaflet';
import Vue2LeafletPolylineDecorator from 'vue2-leaflet-polylinedecorator'
import {
  Carousel,
  Slide
} from 'vue-carousel';
import {
  GestureHandling
} from "leaflet-gesture-handling";

import L from "leaflet";
import filter from '@/service/filter/filter.sub';
import ShoeLog from "@/components/ShoeLog";
import {
  pullAt
} from "lodash";

L.Map.addInitHook("addHandler", "gestureHandling", GestureHandling);
delete L.Icon.Default.prototype._getIconUrl;

export default {
  name: 'MapView',
  data() {
    return {
      path: process.env.VUE_APP_DATA_PATH,

      patterns(color) {
        return [{
            offset: 0,
            repeat: 1,
            symbol: L.Symbol.dash({
              pixelSize: 5,
              pathOptions: {
                color: color,
                weight: 2.5,
              }
            })
          },
          {
            offset: "5%",
            repeat: "0",
            symbol: L.Symbol.arrowHead({
              pixelSize: 10,
              pathOptions: {
                color: color,
              },
              polygon: false
            })
          }
        ]
      },

      searchPoint: true,
      mapLine: true,
      mapPoint: true,
      openshoe: false,
      getShoeLine: [],

      // url: "https://{s}.basemaps.cartocdn.com/rastertiles/voyager_labels_under/{z}/{x}/{y}{r}.png",
      url: 'https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png',
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Tiles style by <a href="https://www.hotosm.org/" target="_blank">Humanitarian OpenStreetMap Team</a> hosted by <a href="https://openstreetmap.fr/" target="_blank">OpenStreetMap France</a>',
      TranLatlng(lat, lng) {
        return L.latLng(lat, lng);
      },
      FilterIcon(name) {
        return filter.filter(this.SelectKid, {
          name: name
        })[0];
      },

      autoPanPadding: [window.screen.width / 2, window.screen.height / 2]
    }
  },
  watch: {
    openshoedate(data) {
      const result = async () => {
        this.getShoeLine = [];
        if (data && store.state.schedule.movelocation.data[data]) {
          store.state.schedule.movelocation.data[data].forEach((item, keys) => {
            const tmp = [item.lat, item.lng];
            if (keys > 0) {
              let tprepoint = store.state.schedule.movelocation.data[data][
                keys - 1
              ];
              this.getShoeLine.push({
                latlng: [
                  [tprepoint.lat, tprepoint.lng],
                  [item.lat, item.lng]
                ],
              });
            }
          });
        }
      }
    },
    "SearchSelected"(data) {
      this.closePopup();

      if (data) {
        this.$refs[data][0].mapObject.openPopup();
      }

      // this.$refs[data][0]
      // console.log(this.$refs[data]);
    },
    "ClickMyPoint"(data) {
      this.closePopup();
      if (data) {
        this.$refs["M" + data][0].mapObject.openPopup();
      }
    },

    "Mylocation.isCheck"(data) {
      if (data[0]) {
        if (navigator.geolocation) {
          store.dispatch("schedule/StartReport");;
        } else {
          console.log("Geolocation is not supported by this browser.");
        }
      } else {
        store.commit("schedule/StopReport");
      }
    }
  },
  methods: {
    checkdata(data) {
      console.log(data);
    },
    openSearchBar() {
      this.$store.commit("Public/setOpenSearch", !this.$store.state.Public.openSearch);
    },
    openSearchBox() {
      this.$emit("openSearchBox");
    },
    toMyLocation() {
      this.$store.dispatch("Public/getMyLocation", true);

      // navigator.geolocation.getCurrentPosition((position) => {
      //   this.myPoints = position;
      //   const itude = [position.coords.latitude, position.coords.longitude];
      //   this.myPoint = itude;
      //   this.$store.dispatch("map/changeMapCenter", itude);
      // }, (error) => {
      //   this.myPoints = error.message;
      // });
    },
    point(x, y) {
      return new L.point(x, y);
    },
    btnOpenShoe() {
      this.openshoe = !this.openshoe;
    },
    closePopup() {
      Object.keys(this.$refs).forEach((item, keys) => {
        if (this.$refs[item][0]) {
          this.$refs[item][0].mapObject.closePopup();
        }
      });
    },
    trymeseesee() {
      console.log("ioo");
    },
    goinLoaction(location) {
      this.$emit("update:mapcenter", [parseFloat(location.lat), parseFloat(location.lng)])
    },

    zoomUpdated(zoom) {
      // update map zoom
      this.$store.commit("map/setZoom", zoom);
      // this.$emit("update:zoom", zoom)
    },

    centerUpdated(itude) {
      // update map center
      const point = [itude.lat, itude.lng];
      this.$store.commit("map/setMapCenter", point);

      // this.$emit("update:mapcenter", [point.lat, point.lng]);
    },
    boundsUpdated(bounds) {
      // this.$emit("update:mapbounds",zoom)
      // this.bounds = bounds;
    },
    PointInDay(value) {
      return filter.filter(this.GetSchedule, {
        place_id: value.place_id
      });
    },
    clicktest(data) {
      this.$refs[data][0].mapObject.openPopup();
    },
    ChangeDayAll() {

    },
  },
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LTooltip,
    LControlLayers,
    LIcon,
    LPolyline,
    LLayerGroup,
    LCircleMarker,
    LControlZoom,
    draggable,
    'v-polyline-decorator': Vue2LeafletPolylineDecorator,
    ShoeLog,
    Carousel,
    Slide
  },

  props: {
    zoom: Number,
    mapcenter: Array,
    SearchList: Array,
    SearchMarkClick: Function,
    SearchSelected: String,
    AddSchedule: Function,
    Markerclick: Function,
    ClickMyPoint: String,
    PointMethod: Object,
    showPointInfor: Function,
  },
  filter: {

  },
  computed: {
    getRealyUrl() {
      return (path) => {
        if (path.indexOf("data/") > -1) {
          return this.path + path;
        } else {
          return path;
        }
      }
    },
    IsSearchBox: {
      get() {
        return this.$store.state.schedule.showSearchBox;
      }
    },
    getPicture() {
      return (data) => {
        if (data) {
          return data.PictureUrl;
        }

        return "../assets/img/error1.jpg";
      }
    },

    dayColor: {
      get() {
        return store.state.schedule.dayColor;
      }
    },

    openshoedate: {
      get() {
        return store.state.schedule.movelocation.openshoedate;
      },
    },

    getShoePoint() {
      const tmp = [];
      if (this.openshoedate) {
        if (store.state.schedule.movelocation.data[this.openshoedate] && store.state.schedule
          .movelocation.data[this.openshoedate].length > 0) {

          tmp.push({
            class: "pms-marker-blink-start",
            data: store.state.schedule.movelocation.data[this.openshoedate][0]
          });
          if (store.state.schedule.movelocation.data[this.openshoedate].length > 1) {
            tmp.push({
              class: "pms-marker-blink",
              data: store.state.schedule.movelocation.data[this.openshoedate][store.state
                .schedule.movelocation.data[this.openshoedate].length - 1
              ]
            });
          }
        }
      }
      return tmp;
    },

    mylocation: {
      get() {
        return this.$store.state.Public.myPoint;
      }
    },

    Mylocation: {
      get() {
        return store.state.schedule.autoreportGPS;
      }
    },

    ScheduleList: {
      get() {
        return store.state.schedule.edit.ScheduleList;
      },
      set: (data) => {
        console.log(data);
      }
    },

    goinstyle: {
      get() {
        let width = 0;

        if (this.ToLocation.length > 0) {}
        return {
          width: "auto"
        };
      }
    },

    ToLocation: {
      get() {
        return store.state.schedule.edit.ToLocation;
      }
    },

    lang: {
      get() {
        return store.state.Public.lang.schedule;
      }
    },

    SelectKid: {
      get() {
        return store.state.schedule.edit.selectKid
      }
    },

    quittype: {
      get() {
        return store.state.schedule.quittype;
      }
    },

    GetSchedule: {
      get() {
        return store.getters['schedule/GetSchedule'];
      },
    },

    GetScheduleInfo: {
      get() {
        return store.state.schedule.edit.data;
      },
    },

    getcenter: {
      get() {
        return L.latLng(this.mapcenter[0], this.mapcenter[1]);
      }
    },

    dynamicSize() {
      return [32, 37];
    },

    dynamicAnchor() {
      return [12, 28];
    },

    searchAnchor() {
      return [6, 48];
    },

    Market() {
      const field = ["title", "info", "uid", "method", "latlng"];

      return this.sourceMarket.map((item, keys) => {
        let tmp = {}
        field.forEach((it) => {
          tmp[it] = (item[it] || "");
        });
        return tmp;
      })
    },

    SearchData() {
      const field = ["title", "info", "uid", "type", "latlng", "method", "color"];
      return this.sourceSearchData.map((item, keys) => {
        let tmp = {}
        field.forEach((it) => {
          tmp[it] = (item[it] || "");
        });
        return tmp;
      });
    },


    drawlatline() {
      let linelist = [];
      let prepoint = [];

      this.GetSchedule.forEach((item, keys) => {
        prepoint[item.day] = (prepoint[item.day]) ? prepoint[item.day] : "";

        if (prepoint[item.day]) {
          let tprepoint = prepoint[item.day];
          linelist.push({
            latlng: [
              [tprepoint.lat, tprepoint.lng],
              [item.lat, item.lng]
            ],
            color: this.dayColor[item.day % 7],
            show: item.subshow
          });
        }

        prepoint[item.day] = item;
      });
      return linelist;
    },

    newDraw() {
      let linelist = [];
      let prepoint = [];

      this.GetSchedule.forEach((item, keys) => {
        prepoint[item.day] = (prepoint[item.day]) ? prepoint[item.day] : "";
        if (!linelist[item.day]) {
          linelist.push({
            latlng: [],
          })
        }

        if (prepoint[item.day]) {
          let tprepoint = prepoint[item.day];
          linelist[item.day].latlng.push(L.latLng(tprepoint.lat, tprepoint.lng));
          linelist[item.day].latlng.push(L.latLng(item.lat, item.lng));
          linelist[item.day].color = this.dayColor[item.day % 7];
          linelist[item.day].show = item.subshow;
        }

        prepoint[item.day] = item;
      });

      return linelist;
    }
  }
};
