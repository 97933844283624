<template>
  <div>
    <b-modal v-model='show' footer-class='bg-white' :hide-footer='true' :centered='true'
      @hide='shoeclose()' title='Shoe Log' body-class='pms-bmodel-body'>
      <div slot="default" slot-scope="{ hide }" class='h-100 overflow-auto'>
        <div class="card" v-for='item in getShoeLog'>
          <div class="card-header">
            <div class="d-flex justify-content-between align-items-center">
              <div @click='toggleshow(item)' class='cur-print'>{{item.date}} <b-badge>
                  {{item.sub.length}}</b-badge>
              </div>
              <div>
                <a href="#" @click.prevent='shoedate(item.date)' class='pms-a'
                  :class="{'pms-a-close': !(opendate==item.date)}">
                  <font-awesome-icon :icon="['fas', (opendate==item.date)?'eye':'eye-slash']"
                    class='fa-2x' />
                </a>
              </div>

            </div>
          </div>
          <!--  -->
          <div class='pms-collapse-hm' v-if='showdate.indexOf(item.date)>-1'>
            <b-collapse :visible="showdate.indexOf(item.date)>-1" :id='"DD_"+item.date'>
              <table class='table table-borded table-striped table-sm'>
                <thead>
                  <tr>
                    <th scope="col"></th>
                    <th scope="col">緯度</th>
                    <th scope="col">經度</th>
                    <th scope="col">時間</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for='(sub,index) in item.sub'>
                    <td scope='row'>{{index+1}}</td>
                    <td>{{sub.lat}}</td>
                    <td>{{sub.lng}}</td>
                    <td>{{sub.createtime |moment("YY-MM-DD HH:mm:ss")}}</td>
                  </tr>
                </tbody>
              </table>
            </b-collapse>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
  import ctrl from "./js/ShoeLog";
  export default ctrl;

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>