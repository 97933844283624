<template>
  <div class="h-100 w-100">
    <div class="h-100 w-100 position-relative">
      <!-- {{newDraw}} -->
      <!-- {{getcenter}} - {{zoom}} -->
      <!-- {{myPoints}} -->
      <!-- {{mylocation}} -->
      <l-map class="w-100" :zoom="zoom" :center="getcenter" :zoom-animation="true" :ref='"OPMAP"'
        :inertia-deceleration="3000" :ease-linearity="1" @update:center="centerUpdated"
        @update:zoom="zoomUpdated" @update:bounds="boundsUpdated"
        :options="{gestureHandling:false, zoomControl:false}">
        <l-control-zoom style="top: 100px"></l-control-zoom>
        <l-circle-marker :lat-lng="getcenter" :radius="1" :color="'red'" />

        <!-- 我的定位點 ? -->
        <!-- mylocation -->
        <!-- <l-circle-marker :lat-lng="TranLatlng(mylocation[0], mylocation[1])" :radius="1"
          :color="'black'" v-if="mylocation[0] != null" /> -->

        <l-marker :lat-lng="TranLatlng(mylocation[0], mylocation[1])" v-if="mylocation[0] != null">
          <l-icon :icon-anchor="[6,19]" class-name="fas fa-plus-square">
            <div class="d-flex flex-column align-items-center">
              <b-icon class="fa-3x cur-print text-danger" icon="plus-circle"></b-icon>
            </div>
          </l-icon>
        </l-marker>

        <l-marker :lat-lng="TranLatlng(Mylocation.mylocation.lat,Mylocation.mylocation.lng)"
          v-if='Mylocation.isCheck[0]'>
          <l-icon :icon-anchor="dynamicAnchor" class-name="fas fa-plus-square">
            <font-awesome-layers full-width class="fa-2x cur-print align-items-center pt-0">
              <font-awesome-icon :icon="['fas', 'map-marker']"
                style='font-size:2.2rem;margin-left:0.0rem' />
              <font-awesome-layers-text class='m-auto'
                style='font-size:60%;color:#FFF;top:0.5rem;margin-left:0rem;z-index:990'
                value="M" />
            </font-awesome-layers>
          </l-icon>
        </l-marker>
        <!-- 我的定位點 ? -->

        <!-- 地圖 -->
        <l-tile-layer :url="url" :attribution="attribution" :visible="true">
        </l-tile-layer>
        <!-- 地圖 -->

        <!-- 行程點 -->
        <l-marker class="ops" :lat-lng="TranLatlng(i.lat, i.lng)"
          :ref="'M'+i.place_id+i.day+i.index" v-for="(i,index) in GetSchedule"
          :key="'point__'+index" v-if="i.subshow"
          @click="Markerclick(i.place_id, i.day, i.index, i)">

          <!-- icon -->
          <l-icon :icon-anchor="searchAnchor">
            <!-- <div class="headline marker-popuer text-center">
              <template>
                <div>{{i.name}}</div>
              </template>
            </div> -->
            <div class="d-flex flex-column align-items-center">
              <div class="d-flex justify-content-center position-relative"
                :style="{color:dayColor[parseInt(i.day % 7)]}">
                <b class="w-100 position-absolute text-center rounded-circle bg-white d-flex align-items-center justify-content-center"
                  style="height:30px; width:30px;max-height:30px; max-width:30px; top:3px; z-index: 201">
                  <span style="font-size:16px;">{{i.index+1}}</span>
                </b>

                <b-icon class="fa-4x cur-print" icon="geo-alt"></b-icon>

                <div class="position-absolute marker-popuer text-center p-1 shadow-sm rounded"
                  style="font-size:16px; bottom: 55px" v-if="mapPoint">
                  <span style="white-space: nowrap;">{{i.name}}</span>
                  <small>{{(i.price) ? i.price.price : ''}}</small>
                </div>
                <!-- <b class="w-100 position-absolute text-center" style="left: 0; top:5px">
                  {{index+1}}
                </b> -->
              </div>
              <!-- <font-awesome-layers full-width class="fa-2x cur-print align-items-center p-0">
                <font-awesome-icon :icon="['fas', 'map-marker-alt']" size="lg"
                  :style="{color:dayColor[parseInt(i.day % 5)]}" />
                <font-awesome-layers-text class="text-white font-up-0" style="top:10px; z-index:200"
                  :value="i.index+1" />
              </font-awesome-layers> -->
            </div>

          </l-icon>

          <!-- hover phone delete -->
          <!-- <l-tooltip :options="{direction:'top', offset: point(0, -35), opacity:'1'}">
            <template>
              <div class="d-flex flex-column align-items-center justify-content-cente"
                style="min-width: 10rem; max-width:10rem">
                <div class="d-flex" style="height: 8rem; min-width: 8rem; max-width:8rem">
                  <img :src="getPicture(i.photo)" :alt="i.name" class="w-100 img-thumbnail">
                </div>
                <span class="w-100 px-1 mt-1 pms-text-nowrap">{{i.name}}</span>
              </div>
            </template>
          </l-tooltip> -->

          <!-- 彈跳資訊 -->
          <!-- v-if="!GetScheduleInfo.Edit" -->
          <!-- v-if='GetScheduleInfo.Edit' -->
          <l-popup class="m-0" style="z-index: 1010"
            :options='{closeOnEscapeKey:false, closeButton:false, closeOnClick:false, offset:point(0, -40),autoPan: false}'>

            <div class="w-100 d-flex flex-column">
              <div class="d-flex p-2 font-up-0 border-bottom bg-dagee"
                style="border-radius: 12px 12px 0 0">
                <b class="flex-fill pms-text-nowrap" @click="showPointInfor(i)">{{i.name}}</b>
                <font-awesome-icon class="cur-print" :icon="['fas', 'times']" size="lg"
                  @click="closePopup()" />
              </div>

              <div
                class="w-100 d-flex flex-column align-items-center justify-content-center p-2 border-bottom">
                <!-- <div class="d-flex align-items-start justify-content-center mb-2"
                    style="height: 8rem; min-width: 8rem; min-height:8rem">
                    <img src="../assets/img/error1.jpg" :alt="i.name" class="w-100 img-thumbnail">
                  </div> -->
                <div class="d-flex align-items-start justify-content-center mb-2 overflow-hidden"
                  style="min-height: 250px" @click='checkdata(i)'>
                  <div class="w-100"
                    v-if="i.info.get_point_image && i.info.get_point_image.length > 0">
                    <carousel class="w-100" :perPage="1" :paginationEnabled="false">
                      <slide v-for="image in i.info.get_point_image">
                        <b-img class="w-100" :src="getRealyUrl(image.image)" rounded
                          :alt="image.name">
                        </b-img>
                        <div class="d-flex align-items-center justify-content-end">
                          <small>上傳者</small>
                          <small class="text-right"
                            style="min-width:100px">{{image.belong_user}}</small>
                        </div>
                        <div class="d-flex align-items-center justify-content-end">
                          <small>來源</small>
                          <small class="text-right" style="min-width:100px">{{image.source}}</small>
                        </div>
                      </slide>

                    </carousel>
                  </div>
                  <!-- <img :src="getRealyUrl(i.info.get_point_image[0].image)"
                    class="w-100 card-img-top" :alt="i.info.get_point_image[0].name"
                    v-if="i.info.get_point_image && i.info.get_point_image.length > 0"> -->

                  <img src="../assets/img/error1.jpg" class="w-100 card-img-top" :alt="i.name"
                    v-else>

                </div>
                <div class="flex-fill d-flex flex-wrap pms-inday">
                  <div class="badge badge-pill badge-success cur-print"
                    :class='{"active": j.index==i.index && i.day==j.day}'
                    @click='PointMethod.EditPoint(j.day,j.info,j.index)'
                    v-for='(j, index) in PointInDay(i)' :key="'pointinfo__'+index">
                    <span class="p-1">Day{{j.day+1}}-{{j.index+1}}</span>
                  </div>
                </div>
              </div>

              <div class="w-100 d-flex align-items-center justify-content-between p-2">

                <a :href='"https://www.google.com/search?q="+i.name'
                  target='_blank'>{{lang.MapView.outSearchInfo}}</a>

                <a :href='"https://www.google.com/maps?q="+i.name'
                  target='_blank'>{{lang.MapView.outSearchInfoMap}}</a>

                <a href="#" class="cur-print" @click='AddSchedule(i.info)'
                  v-if="!!GetScheduleInfo.Edit">
                  <font-awesome-icon :icon="['fas', 'plus']" />
                </a>

              </div>
            </div>

          </l-popup>

          <!-- <l-popup v-if='!GetScheduleInfo.Edit' style='min-width:200px'>
            <div>如果顯示這個畫面請告訴我</div>
            <div class="card">
              <h5 class="card-header">{{i.info.title}}</h5>
              <div class="card-body p-2">
                <p class="card-text location">
                  test
                </p>

                <a :href='"https://www.google.com/search?q="+i.name' target='_blank'
                  class="btn btn-primary text-white  btn-sm">{{lang.MapView.outSearchInfo}}</a>
                <a :href='"https://www.google.com/maps?q="+i.name' target='_blank'
                  class="btn btn-primary text-white  btn-sm float-right">{{lang.MapView.outSearchInfoMap}}</a>
              </div>
            </div>
          </l-popup> -->

        </l-marker>

        <!-- ==================================  搜尋標點  ==================================-->
        <!-- search data -->
        <l-marker :ref="i.place_id" :lat-lng="TranLatlng(i.lat,i.lng)"
          v-for="(i, index) in SearchList" :key="index" @click="SearchMarkClick(i)">

          <l-icon :icon-anchor="searchAnchor">
            <div class="d-flex flex-column align-items-center">
              <div class="d-flex justify-content-center position-relative" :style="{color:i.color}">
                <b-icon class="fa-4x cur-print" icon="geo-alt"></b-icon>

                <div
                  class="position-absolute d-flex flex-wrap align-items-center justify-content-center marker-popuer p-1 shadow-sm rounded"
                  style="font-size:16px; bottom: 55px" v-if="searchPoint">
                  <span style="white-space: nowrap;">{{i.name}}</span>
                  <small v-if="i.price">{{i.price.price| currency()}}</small>
                </div>

                <!-- <div class="marker-popuer text-center p-1 shadow-sm rounded" style="font-size:16px">
                  <span style="white-space: nowrap;">{{i.name}}</span>
                  <small v-if="i.price">{{i.price.price| currency()}}</small>
                </div> -->
              </div>
            </div>

            <!-- <div class="d-flex flex-column align-items-center">
              <div class="marker-popuer text-center p-1 mb-2 shadow rounded">
                <div style="write-space: nowarp">{{i.name}}</div>
                <div>{{(i.price)?i.price:'尚未詢價'}}</div>
              </div>
              <font-awesome-layers full-width class="fa-2x cur-print align-items-center p-0">
                <font-awesome-icon :icon="['fas', i.maintypes]" size="lg"
                  :style="{color:i.color}" />
              </font-awesome-layers>

              
            </div> -->
          </l-icon>

          <!-- <l-popup v-if='GetScheduleInfo.Edit' style='min-width:200px'>
            <h5 class="card-header shadow rounded">
              {{i.name}} test
            </h5>
            <div class="card-body p-2">
              <div class="d-flex flex-wrap pms-inday">
                <div class='inday-item cur-print'
                  @click='PointMethod.EditPoint(j.day,j.info,j.index)'
                  :class='{"active":j.index==i.index&&i.day==j.day}' v-for='j in PointInDay(i)'>
                  D{{j.day+1}}-{{j.index+1}}
                </div>
                <div class='inday-item cur-print text-white' @click='AddSchedule(i)'>
                  <i>
                    <font-awesome-icon :icon="['fas', 'plus']" class='cur-print' />
                  </i>
                </div>
              </div>

            </div>
            <div class="card-footer p-1">
              <a :href='"https://www.google.com/search?q="+i.name' target='_blank'
                class="btn btn-primary text-white  btn-sm">{{lang.MapView.outSearchInfo}}</a>
              <a :href='"https://www.google.com/maps?q="+i.name' target='_blank'
                class="btn btn-primary text-white  btn-sm float-right">{{lang.MapView.outSearchInfoMap}}</a>
            </div>
          </l-popup> -->

          <l-popup v-if='GetScheduleInfo.Edit' class="m-0" style="z-index: 1006"
            :options='{closeOnEscapeKey:false, closeButton:false, closeOnClick:false, offset:point(0, -40), autoPan: false}'>
            <template>
              <div class="w-100 d-flex flex-column">
                <div class="d-flex p-2 font-up-0 border-bottom bg-dagee"
                  style="border-radius: 12px 12px 0 0">
                  <b class="flex-fill pms-text-nowrap" @click="showPointInfor(i)">{{i.name}}</b>
                  <font-awesome-icon class="cur-print" :icon="['fas', 'times']" size="lg"
                    @click="closePopup()" />
                </div>

                <div
                  class="w-100 d-flex flex-column align-items-center justify-content-center p-2 border-bottom">
                  <div class="d-flex mb-2" style="height: 250px">
                    <!-- <img :src="getPicture(i.photo)" :alt="i.name" class="w-100 img-thumbnail"> -->
                    <carousel class="w-100" :perPage="1" :paginationEnabled="false"
                      v-if="i.image && i.image.length > 0">
                      <slide v-for="image in i.image">
                        <b-img class="w-100" :src="getRealyUrl(image.image)" rounded
                          :alt="image.name">
                        </b-img>
                        <div class="d-flex align-items-center justify-content-end">
                          <small>上傳者</small>
                          <small class="text-right"
                            style="min-width:100px">{{image.belong_user}}</small>
                        </div>
                        <div class="d-flex align-items-center justify-content-end">
                          <small>來源</small>
                          <small class="text-right" style="min-width:100px">{{image.source}}</small>
                        </div>
                      </slide>

                    </carousel>

                    <!-- <img :src="getRealyUrl(i.image[0].image)" class="w-100 img-thumbnail"
                      :alt="i.image[0].name" v-if="i.image && i.image.length > 0"> -->

                    <img src="../assets/img/error1.jpg" class="w-100 img-thumbnail" :alt="i.name"
                      v-else>

                  </div>
                  <div class="flex-fill d-flex flex-wrap pms-inday">
                    <div class="badge badge-pill badge-success cur-print"
                      :class='{"active": j.index==i.index && i.day==j.day}'
                      @click='PointMethod.EditPoint(j.day,j.info,j.index)'
                      v-for='(j, index) in PointInDay(i)' :key="'pointpop__'+index">
                      <span class="p-1">Day{{j.day+1}}-{{j.index+1}}</span>
                    </div>
                  </div>
                </div>

                <div class="w-100 d-flex align-items-center justify-content-between p-2">

                  <a :href='"https://www.google.com/search?q="+i.name'
                    target='_blank'>{{lang.MapView.outSearchInfo}}</a>

                  <a :href='"https://www.google.com/maps?q="+i.name'
                    target='_blank'>{{lang.MapView.outSearchInfoMap}}</a>

                  <a href="#" class="cur-print" @click='AddSchedule(i)'>
                    <font-awesome-icon :icon="['fas', 'plus']" />
                  </a>

                </div>
              </div>
            </template>
          </l-popup>


          <!--  -->
          <!-- <l-popup v-if='!GetScheduleInfo.Edit' style='min-width:200px'
            :options='{autoClose:false,closeButton:false}'>
            <h5 class="card-header shadow rounded">
              {{i.name}}
            </h5>
            <div class="card-body p-2">
              <div class="d-flex flex-wrap pms-inday">
                <div class='inday-item cur-print'
                  @click='PointMethod.EditPoint(j.day,j.info,j.index)'
                  :class='{"active":j.index==i.index&&i.day==j.day}' v-for='j in PointInDay(i)'>
                  D{{j.day+1}}-{{j.index+1}}
                </div>
                <div class='inday-item cur-print text-white' @click='AddSchedule(i)'>
                  {{i.name}}
                  <i>
                    <font-awesome-icon :icon="['fas', 'plus']" class='cur-print' />
                  </i>
                </div>
              </div>

            </div>
            <div class="card-footer p-1">
              <a :href='"https://www.google.com/search?q="+i.name' target='_blank'
                class="btn btn-primary text-white  btn-sm">{{lang.MapView.outSearchInfo}}</a>
              <a :href='"https://www.google.com/maps?q="+i.name' target='_blank'
                class="btn btn-primary text-white  btn-sm float-right">{{lang.MapView.outSearchInfoMap}}</a>
            </div>
          </l-popup> -->
        </l-marker>

        <!-- ==================================  搜尋標點  ==================================-->
        <!-- ==================================  畫線條  ==================================-->


        <l-polyline :lat-lngs="line.latlng" :color='line.color' v-for="(line, index) in drawlatline"
          :key="'pointline__' + index" v-if='line.show && mapLine'>
        </l-polyline>

        <!-- <v-polyline-decorator v-for="(line, index) in newDraw" :key="'pointlines__' + index"
          :paths="line.latlng" :patterns="patterns(line.color)" v-if='line.show && mapLine'>
        </v-polyline-decorator> -->

        <!-- <PolylineDecorator v-for="(line, index) in drawlatline" :key="'pointline__' + index"
        :paths="[line.latlng]" :patterns="patterns">
        </PolylineDecorator> -->

        <l-marker :lat-lng="TranLatlng(i.data.lat,i.data.lng)" v-for="(i, index) in getShoePoint"
          :key="'shoepoint__'+index">
          <l-icon class-name="someExtraClass">
            <div class=" rounded-circle" :class='[i.class]' style='width:8px;height:8px;'></div>
          </l-icon>
          <l-tooltip> {{i.data.createtime |moment("HH-mm-ss")}}</l-tooltip>
        </l-marker>

        <l-polyline :lat-lngs="lines.latlng" v-for="(lines, index) in getShoeLine" color='#84b1e8'
          :key="'shoeline__'+index">
        </l-polyline>
        <!-- ==================================  畫線條  ==================================-->
      </l-map>


      <!-- <div class="pms-locationlog-list">
        <button class='pms-locationlog-btn' @click.prevent='btnOpenShoe()'>
          <font-awesome-icon :icon="['fas', 'shoe-prints']" class='cur-print' />
        </button>
      </div> -->

      <!-- <div class="pms-schedule-goin">
        <div :style='goinstyle' class='h-100 oveflow-auto d-flex align-items-center'>
          <div class='pms-schedule-goin-item cur-print' v-for="(i, index) in ToLocation"
            @click='goinLoaction(i)' :key="'location__'+index">
            {{i.name}}-{{i.engName}}
          </div>
        </div>
      </div> -->

      <div class="position-absolute" style="z-index:1004; top:155px; right: 0.85rem;">
        <div style="min-width:30px">
          <div
            class="d-flex flex-column align-items-center justify-content-center border rounded mb-2"
            style="line-height: 1.2; height: 40px; width: 40px; opacity: 0.7"
            @click="mapLine = !mapLine" :class="[mapLine ? 'bg-primary text-white':'bg-white']">
            <!-- <small class="text-center">{{mapLine ? '顯示' : '隱藏'}}</small> -->
            <small class="text-center" style="font-size: 15px; opacity: 1">標線</small>
            <!-- <div class="w-100 text-center">
              <input id="line__mark" type="checkbox" style="width: 20px; height: 20px"
                v-model="mapLine">
            </div>
            <label for="line__mark" class="text-center" style="writing-mode: vertical-lr">標線</label> -->
          </div>
          <div
            class="d-flex flex-column align-items-center justify-content-center border rounded mb-2"
            style="line-height: 1.2; height: 40px; width: 40px; opacity: 0.7"
            @click="mapPoint = !mapPoint" :class="[mapPoint ? 'bg-primary text-white':'bg-white']">
            <!-- <small class="text-center">{{mapPoint ? '顯示' : '隱藏'}}</small> -->
            <span class="text-center" style="font-size: 15px; opacity: 1">標示</span>
            <!-- <div class="w-100 text-center">
              <input id="line__mark" type="checkbox" style="width: 20px; height: 20px"
                v-model="mapLine">
            </div>
            <label for="line__mark" class="text-center" style="writing-mode: vertical-lr">標線</label> -->
          </div>
          <!-- <div class="d-flex align-items-center justify-content-center flex-wrap font-up-3 mb-2">
            <div class="w-100 text-center">
              <input id="point__mark" type="checkbox" style="width: 20px; height: 20px"
                v-model="mapPoint">
            </div>
            <label for="point__mark" class="text-center"
              style="writing-mode: vertical-lr">標示</label>
          </div> -->
          <div class="d-flex align-items-center justify-content-center mb-2"
            style="line-height: 1.2; height: 40px; width: 40px; opacity: 0.7"
            @click="toMyLocation()">
            <font-awesome-icon :icon="['fas', 'map-marker-alt']" class='cur-print text-danger'
              size="2x" />
          </div>

          <div class="d-flex align-items-center justify-content-center"
            style="line-height: 1.2; height: 40px; width: 40px; opacity: 0.7"
            @click="openSearchBox()">
            <font-awesome-icon :icon="['fas', IsSearchBox ?'search-minus' :'search-plus']"
              size="2x" />
          </div>
        </div>
      </div>

      <!-- <div class="position-absolute p-1" style="z-index:1004; top:6rem; right:0.5rem;">
        <div class="border p-1"
          style="width:40px; max-width:40px; background-color:rgba(255,255,255,0.6);">
          <div class="d-flex align-items-center justify-content-center flex-wrap font-up-3 mb-2">
            <div class="w-100 text-center">
              <input id="line__mark" type="checkbox" style="width: 20px; height: 20px"
                v-model="mapLine">
            </div>
            <label for="line__mark" class="text-center" style="writing-mode: vertical-lr">標線</label>
          </div>
          <div class="d-flex align-items-center justify-content-center flex-wrap font-up-3 mb-2">
            <div class="w-100 text-center">
              <input id="point__mark" type="checkbox" style="width: 20px; height: 20px"
                v-model="mapPoint">
            </div>
            <label for="point__mark" class="text-center"
              style="writing-mode: vertical-lr">標示</label>
          </div>
          <div class="d-flex align-items-center justify-content-center mb-2"
            @click="toMyLocation()">
            <font-awesome-icon :icon="['fas', 'map-marker-alt']" class='cur-print text-danger'
              size="2x" />
          </div>

          <div class="d-flex align-items-center justify-content-center" @click="openSearchBox()">

            <font-awesome-icon :icon="['fas', 'search-plus']" size="2x" v-if="!IsSearchBox" />
            <font-awesome-icon :icon="['fas', 'search-minus']" size="2x" v-if="IsSearchBox" />

          </div>
        </div>
      </div> -->

      <div class="position-absolute" style="z-index:1004; top:236px; left:0.5rem;">
        <div style="min-width: 30px">
          <div class="d-flex align-items-center justify-content-center mb-2"
            style="line-height: 1.2; height: 40px; width: 40px; opacity: 0.7"
            @click="openSearchBar()">
            <font-awesome-icon :icon="['fas', 'search']" size="2x" />
          </div>
          <div
            class="d-flex flex-column align-items-center justify-content-center border rounded mb-2"
            style="line-height: 1.2; height: 40px; width: 40px; opacity: 0.7"
            @click="searchPoint = !searchPoint"
            :class="[searchPoint ? 'bg-primary text-white':'bg-white']">
            <!-- <small class="text-center">{{searchPoint ? '搜尋' : '隱藏'}}</small> -->
            <span class="text-center" style="font-size: 15px; opacity: 1">標示</span>
          </div>

          <div
            class="d-flex align-items-center justify-content-center border rounded-circle bg-primary "
            style="line-height: 1.2; height: 40px; width: 40px; opacity: 0.7"
            @click="btnOpenShoe()">
            <font-awesome-icon :icon="['fas', 'shoe-prints']" class="cur-print text-white" />
          </div>




        </div>
      </div>
    </div>

    <ShoeLog :isopen.sync='openshoe' :opendate.sync='openshoedate'></ShoeLog>

  </div>
</template>

<script>
  import ctrl from "./js/MapView";
  export default ctrl;

</script>

<style lang='scss'>
  .leaflet-control-attribution {
    // display: none;
  }

  .leaflet-popup-content {
    margin: 0;
  }

  #osmmap {
    width: 100%;
  }

  .marker-shoe-title {
    min-width: 5rem;
    background-color: rgba(0, 0, 0, 0.6);
    font-size: 80%;
    color: #FFF;
    margin-left: -1.5rem;
  }

  .marker-popuer {
    background-color: rgba(255, 255, 255, 0.8);
    color: #000;
    min-width: 6rem;
    // width: 6rem;
    // max-width: 8rem;
  }

  .leaflet-popup-content-wrapper {
    padding: 0;

    .leaflet-popup-content {
      min-width: 18rem;
      max-width: 18rem;
    }
  }

  .leaflet-top {
    top: 70px
  }

  .teststtt {
    &::before {
      content: "";
      width: 12px;
      height: 12px;
      border: solid #fff;
      border-width: 2px 2px 0 0;
      transform: translate(-50%, -50%) rotate(-45deg);
      position: absolute;
      left: 50%;
      top: 70%;

    }
  }

</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
